import cn from 'classnames'

import { type DotIndicatorProps } from './types'

const DotIndicator = ({
  id,
  count,
  onClick,
  className,
  activeIndex,
}: DotIndicatorProps) => {
  const exactCount = Math.floor(count)

  if (exactCount < 1) {
    return null
  }

  return (
    <div
      role="tablist"
      aria-label={id}
      className={cn(
        className,
        'flex w-full items-center justify-center gap-x-1',
      )}
    >
      {Array.from(Array(exactCount).keys())?.map((d) => {
        return (
          <span
            key={d}
            id={`tab-${String(id)}-${d}`}
            role="tab"
            aria-selected={activeIndex === d ? 'true' : 'false'}
            aria-controls={`panel-${String(id)}-${d}`}
            onClick={() => {
              onClick(d)
            }}
            aria-label={`${String(id)} ${d + 1}`}
            className={cn(
              'my-transition h-2.5 w-2.5 list-none rounded-full bg-dark/25 dark:bg-light/10',
              {
                '!h-3 !w-3 !bg-primary dark:!bg-primary': d === activeIndex,
                'cursor-pointer': d !== activeIndex,
              },
            )}
          />
        )
      })}
    </div>
  )
}

export default DotIndicator
