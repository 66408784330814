import { type TouchEventHandler, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { MdPerson } from 'react-icons/md'

import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import Button from '@/components/Button'
import DotIndicator from '@/components/DotIndicator'
import Ratings from '@/components/Ratings'
import { TESTI } from '@/utils/constants/data'
import { ROBOTO_BOLD } from '@/utils/constants/themes'
import { debounce } from '@/utils/helper/common'

import { type TestiSectionProps } from '../types'

const TestiSection = ({ data }: TestiSectionProps) => {
  const { t } = useTranslation(['common', 'home'])

  // let touched = 0
  const [activeIndex, setActiveIndex] = useState(0)
  const [touched, setTouched] = useState<number>(0)

  const handleNext = () => {
    if (activeIndex === TESTI.length - 1) setActiveIndex(0)
    else setActiveIndex(activeIndex + 1)
  }
  const handlePrev = () => {
    if (activeIndex === 0) setActiveIndex(TESTI.length - 1)
    else setActiveIndex(activeIndex - 1)
  }

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouched(e.touches?.[0]?.clientX)
  }

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    const released = e.touches?.[0]?.clientX
    if (released - touched < -25) handleNext()
    if (released - touched > 25) handlePrev()
  }

  const getPosition = (i: number, _activeIndex: number) => {
    if (i === _activeIndex) return 'center'
    if (
      (_activeIndex === 0 && i === TESTI.length - 1) ||
      i === _activeIndex - 1
    )
      return 'left'
    if (
      i === _activeIndex + 1 ||
      (_activeIndex === TESTI.length - 1 && i === 0)
    )
      return 'right'
    return 'hidden'
  }

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 overflow-x-hidden py-16 lg:justify-center lg:gap-x-8 lg:gap-y-24 lg:py-32 xl:gap-x-36">
      <div className="flex w-full justify-center">
        <h2
          className={cn(
            'h2 text-center dark:text-primary',
            ROBOTO_BOLD.className,
          )}
          data-aos="fade-down"
        >
          {t('home:testi_title')}
        </h2>
      </div>

      <div
        className="group relative flex h-72 w-full justify-center"
        data-aos="zoom-in"
      >
        <Button
          type="outline"
          buttonProps={{
            onClick: handlePrev,
            'aria-label': String(t('home:prev_testi')),
            className:
              'my-transition rounded-full !p-3 absolute top-1/2 -translate-y-1/2 left-0 opacity-0 group-hover:opacity-100 z-10',
          }}
        >
          <AiOutlineLeft size={20} />
        </Button>
        {[...data].map(({ id, text, name, date, rating }, i) => {
          return (
            <div
              key={id}
              role="tabpanel"
              id={`panel-testimoni-${String(i)}`}
              aria-labelledby={`tab-testimoni-${String(i)}`}
              className={cn(
                'my-transition shadow-primary-md dark:shadow-primary-md-dark absolute top-1/2 flex h-max w-full max-w-md -translate-y-1/2 flex-col gap-y-4 rounded-lg bg-white p-8 dark:bg-dark',
                {
                  'z-10': getPosition(i, activeIndex) === 'center',
                  'translate-x-3/4 scale-75 opacity-25':
                    getPosition(i, activeIndex) === 'right',
                  '-translate-x-3/4 scale-75 opacity-25':
                    getPosition(i, activeIndex) === 'left',
                  'translate-x-0 scale-50 opacity-0':
                    getPosition(i, activeIndex) === 'hidden',
                },
              )}
              onTouchStart={handleTouchStart}
              onTouchMove={debounce(handleTouchMove, 50)}
            >
              <p className="">{text}</p>
              <div className="flex items-center gap-x-1">
                <MdPerson />
                <span className="text-sm">{name}</span>
              </div>
              <div className="flex items-center gap-x-4 text-sm">
                <Ratings rating={rating} />
                <span>{date}</span>
              </div>
            </div>
          )
        })}
        <Button
          type="outline"
          buttonProps={{
            onClick: handleNext,
            'aria-label': String(t('home:next_testi')),
            className:
              'my-transition rounded-full !p-3 absolute top-1/2 -translate-y-1/2 right-0 opacity-0 group-hover:opacity-100 z-10',
          }}
        >
          <AiOutlineRight />
        </Button>
      </div>

      <DotIndicator
        id="testimoni"
        count={data.length}
        activeIndex={activeIndex}
        onClick={(i) => {
          setActiveIndex(i)
        }}
      />
    </div>
  )
}

export default TestiSection
