export function debounce<T, Params extends T[]>(
  func: (...args: Params) => void,
  timeout = 300,
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export function throttle(func: () => void, waitTime: number) {
  let isQueued = false
  return () => {
    if (!isQueued) {
      isQueued = true
      setTimeout(() => {
        isQueued = false
        func()
      }, waitTime)
    }
  }
}
