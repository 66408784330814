import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs'

import cn from 'classnames'

import { type RatingProps } from './types'

const Ratings = ({
  className,
  rating = 0,
  size = 16,
  starClassName,
}: RatingProps) => {
  if (rating < 0 || rating > 5) {
    return null
  }

  const fullFilled = Math.floor(rating)
  const halfFilled = rating - fullFilled > 0 ? 1 : 0
  const outlined = 5 - (fullFilled + halfFilled)

  const generateArray = (num: number) => {
    return Array.from(Array(num).keys())
  }

  return (
    <div className={cn(className, 'flex items-center gap-1 text-accent')}>
      {generateArray(fullFilled).map((d) => (
        <BsStarFill key={d} size={size} className={starClassName} />
      ))}
      {generateArray(halfFilled).map((d) => (
        <BsStarHalf key={d} size={size} className={starClassName} />
      ))}
      {generateArray(outlined).map((d) => (
        <BsStar key={d} size={size} className={starClassName} />
      ))}
    </div>
  )
}

export default Ratings
